import React, { forwardRef, useContext, useState } from 'react'
import * as stdTableSetup from '../../../hooks/useStandardTableSetup'
import { findOpenPayables } from '../../../actions/ZClaimFundingActions'
import {
  Box,
  Checkbox,
  Chip,
  FormControl,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
} from '@material-ui/core'
import { AspectRatio as IconAspectRatio } from '@material-ui/icons'
import ViewPayableDetailOverlay from './ViewPayableDetailOverlay'
import {
  getPayableStatusLabel,
  getPayablePayeeTypeDisplay,
  PayableStatus,
  payablePayeeType,
} from './PayableDetail'
//@ts-ignore
import styled from 'styled-components'
import ManualPay from './ManualPay'
import WriteoffFarleyUncollectible from './WriteoffFarleyUncollectible'

const StyledTableWrap = styled.div`
  // data-table fixed height related styling
  &.full-fixed {
    &.zclaims-invoice-table-wrap,
    .std-table,
    .base-table-display {
      height: 100%;
    }

    .base-table-display {
      overflow: clip;
      overflow-clip-margin: 1rem;
    }

    .datatable-common-wrapper {
      height: calc(100% - 3rem);

      .MuiTableContainer-root {
        max-height: none;
        height: 100%;
      }
    }

    .std-table {
      &.has-before {
        display: flex;

        .lefty {
          position: sticky;
          top: 20px;
          align-self: start;
          padding-right: 1rem;
        }

        .base-table-display {
          margin-top: 0;
        }
      }
    }
  }

  .MuiIconButton-sizeSmall {
    padding: 7px;
    font-size: 1.3rem;
  }
`
export * from '../../../hooks/useStandardTableSetup'

export interface payableTableRecordDetail {
  ZClaimID: number
  ZClaimAmount: string
}

export interface payableTableRecord {
  RecordID: string
  PayEntityID: number
  PayEntityName: string
  PayEntityType: payablePayeeType
  PayEntityAddress: string
  ClaimCount: number
  PayableStatus: PayableStatus
  Amount: string
  Details: payableTableRecordDetail[]
}

export const defaultColumns = Object.freeze({
  ViewPayableDetails: {
    name: '',
    details: {
      dataFormat(_: any, row: payableTableRecord): any {
        return <InlineViewPayableDetail data={row} />
      },
    },
  },
  PayEntityType: {
    name: 'Type',
    details: { dataFormat: getPayablePayeeTypeDisplay },
  },
  PayEntityName: {
    name: 'Payee',
    details: {
      dataFormat(_: any, row: payableTableRecord): any {
        return (
          <div>
            <div>{row.PayEntityName}</div>
            <div
              style={{
                fontSize: '0.875em',
                fontStyle: 'italic',
                color: 'secondary',
              }}>
              {row.PayEntityAddress}
            </div>
          </div>
        )
      },
    },
  },
  ClaimCount: { name: 'Claim Count' },
  PayableStatus: {
    name: 'Payable Status',
    details: {
      dataFormat(_: any, row: payableTableRecord): any {
        return getPayableStatusLabel(row.PayableStatus as PayableStatus)
      },
    },
  },
  Amount: {
    name: 'Amount',
    details: {
      dataFormat(_: any, row: payableTableRecord): any {
        const amount = parseFloat(row.Amount)
        const style = amount < 0 ? { color: 'red' } : {}
        return <div style={style}>{formatAmount(row.Amount)}</div>
      },
    },
  },
  ManualPaymentButton: {
    name: '',
    details: {
      dataFormat(_: any, row: payableTableRecord): any {
        return parseFloat(row.Amount) > 0 ? (
          <InlineManualPaymentButton data={row} disabled={false} />
        ) : null
      },
    },
  },
  WriteoffFarleyUncollectibleButton: {
    name: '',
    details: {
      dataFormat(_: any, row: payableTableRecord): any {
        return parseFloat(row.Amount) < 0 &&
          row.PayEntityName === 'JP Farley' ? (
          <InlineWriteoffFarleyUncollectibleButton data={row} />
        ) : null
      },
    },
  },
})

const defaultSort = { col: 'PayEntityName', dir: 'asc' }

export const Table = forwardRef(function Table(
  {
    customColumns = defaultColumns,
    apiEndpoint = findOpenPayables,
    DataTableProps = {},
    ...passThrough
  }: stdTableSetup.props & Partial<any>,
  ref: any
): React.ReactElement | null {
  const { TableDisplay } = stdTableSetup.useStandardTableSetup(
    {
      ...passThrough,
      customColumns,
      apiEndpoint,
      defaultSort,
      initPageSize: 100,
      DataTableProps: {
        isRowCheckable: (row: payableTableRecord) => {
          return (
            row.PayEntityType === payablePayeeType.BillingEntity &&
            (row.PayableStatus === PayableStatus.EopOnly ||
              parseFloat(row.Amount) >= 0)
          )
        },
        keyProp: 'RecordID',
        ...DataTableProps,
        rowsPerPage: [10, 25, 50, 100, 250, 500],
        density: 'small',
      },
    },
    ref
  )

  return (
    <StyledTableWrap
      className={`zclaims-invoice-table-wrap ${passThrough?.className || ''}`}>
      {TableDisplay}
    </StyledTableWrap>
  )
})

function formatAmount(amount: string): string {
  if (!amount || amount === '0') return '$0.00'
  return `$${amount.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
}

function InlineViewPayableDetail({
  data,
}: {
  data: payableTableRecord
}): React.ReactElement {
  const { refresh } = useContext(stdTableSetup.baseContext)
  const ref = React.useRef<any>(null)

  return (
    <>
      <IconButton size="small" onClick={() => ref?.current?.open()}>
        <IconAspectRatio fontSize="inherit" />
      </IconButton>
      <ViewPayableDetailOverlay
        ref={ref}
        payEntityID={data.PayEntityID}
        payableStatus={data.PayableStatus}
        onClose={() => refresh()}
      />
    </>
  )
}

function InlineManualPaymentButton({
  data,
  disabled,
}: {
  data: payableTableRecord
  disabled: boolean
}): React.ReactElement {
  const { refresh } = useContext(stdTableSetup.baseContext)

  return (
    <ManualPay
      disabled={disabled}
      payEntityID={data.PayEntityID}
      payableStatus={data.PayableStatus}
      onClose={() => refresh()}
    />
  )
}

function InlineWriteoffFarleyUncollectibleButton({
  data,
}: {
  data: payableTableRecord
}): React.ReactElement {
  const { refresh } = useContext(stdTableSetup.baseContext)

  return <WriteoffFarleyUncollectible data={data} onClose={() => refresh()} />
}

export function FilterPayableStatus(): React.ReactElement {
  // @ts-ignore - typescript is getting this wrong; useId does exist
  const [internalID] = React.useId()
  const [statuses, setStatuses] = React.useState<PayableStatus[]>([
    PayableStatus.BilledCollected,
    PayableStatus.EopOnly,
  ])
  const { setFilterImmediate } = useContext(stdTableSetup.baseContext)
  const [items] = useState(() => {
    return Object.values(PayableStatus).map((value: PayableStatus) => ({
      value,
      label: getPayableStatusLabel(value),
    }))
  })

  const onChange = (e: any, sel: any) => {
    const {
      target: { value },
    } = e
    const newStatuses = typeof value === 'string' ? value.split(',') : value

    setStatuses(newStatuses)
    setFilterImmediate({ payableStatuses: newStatuses })
  }

  return (
    <FormControl
      variant="outlined"
      margin="none"
      size="small"
      fullWidth
      style={{ minWidth: 170 }}>
      <InputLabel shrink id={internalID}>
        Payable Status
      </InputLabel>
      <Select
        labelId={internalID}
        multiple
        value={statuses}
        onChange={onChange}
        label="Payable Status"
        renderValue={(value: any) => (
          <Box style={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
            {value.map((status: PayableStatus) => (
              <Chip key={status} label={getPayableStatusLabel(status)} />
            ))}
          </Box>
        )}
        autoWidth>
        {items.map((item: any) => (
          <MenuItem key={item.label} value={item.value}>
            <Checkbox checked={statuses.includes(item.value)} />
            <ListItemText primary={item.label} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export function FilterPayeeType(): React.ReactElement {
  // @ts-ignore - typescript is getting this wrong; useId does exist
  const [internalID] = React.useId()
  const { filter, setFilterImmediate } = useContext(stdTableSetup.baseContext)
  const [items] = useState(() => {
    return Object.values(payablePayeeType).map((value: payablePayeeType) => ({
      value,
      label: getPayablePayeeTypeDisplay(value),
    }))
  })

  const onChange = (e: any, sel: any) => {
    if (sel.props?.value === 'all') {
      return setFilterImmediate({ payEntityTypes: null })
    }
    setFilterImmediate({ payEntityTypes: [sel.props.value] })
  }

  const v = filter.payEntityTypes || payablePayeeType.All

  return (
    <FormControl
      variant="outlined"
      margin="none"
      size="small"
      fullWidth
      style={{ minWidth: 170 }}>
      <InputLabel shrink id={internalID}>
        Payee Type
      </InputLabel>
      <Select
        labelId={internalID}
        value={v}
        onChange={onChange}
        label="Payee Type"
        autoWidth>
        {items.map((item: any) => (
          <MenuItem key={item.label} value={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}
