import React, { useState } from 'react'
import DesignSuite2023 from '../../../components/DesignSuite2023'
import { payableTableRecord } from './PayableTable'
import useErrorHandlers from '../../../hooks/useErrorHandlers'
import useSnackbar, { SnackbarTypeSuccess } from '../../../hooks/useSnackbar'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from '@material-ui/core'
import {
  renderNotesField as RenderNotesField,
  SetterArgs,
} from '../../../components/Inputs/standard'
import {
  postWriteoffFarleyUncollectible,
  PostWriteoffFarleyUncollectibleParams,
  WriteoffDetail,
} from '../../../actions/ZClaimFundingActions'
import styled from 'styled-components'

type props = {
  data: payableTableRecord
  onClose?(): void
}

const StyledContent = styled.div`
  .autocomplete-root {
    width: auto;
    .MuiFormControl-fullWidth {
      min-width: 0;
    }
  }
`

export default function WriteoffFarleyUncollectible({
  data,
  onClose,
}: props): React.ReactElement {
  function initialPostSendWriteoffFarleyUncollectibleParams(
    data: payableTableRecord
  ): PostWriteoffFarleyUncollectibleParams {
    return {
      notes: null,
      details: (data.Details || []).map(
        (detail): WriteoffDetail => ({
          zClaimID: detail.ZClaimID,
          // endpoint wants a positive number
          amount: detail.ZClaimAmount.startsWith('-')
            ? detail.ZClaimAmount.slice(1)
            : detail.ZClaimAmount,
        })
      ),
    }
  }
  const [isWorking, setIsWorking] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [
    postWriteoffFarleyUncollectibleParams,
    setPostWriteoffFarleyUncollectibleParams,
  ] = useState<PostWriteoffFarleyUncollectibleParams>(
    initialPostSendWriteoffFarleyUncollectibleParams(data)
  )

  const { catchAPIError } = useErrorHandlers()
  const { showForDuration: showSnackbar } = useSnackbar()

  const setter = ({ name, value }: SetterArgs) => {
    setPostWriteoffFarleyUncollectibleParams(
      (curr: PostWriteoffFarleyUncollectibleParams) => ({
        ...curr,
        [name]: value,
      })
    )
  }

  const handleClose = () => {
    onClose?.()
    setIsOpen(false)
  }

  function doPostWriteoffFarleyUncollectible() {
    setIsWorking(true)
    let params: PostWriteoffFarleyUncollectibleParams =
      postWriteoffFarleyUncollectibleParams

    postWriteoffFarleyUncollectible(params)
      .then((res: any) => {
        showSnackbar(`Farley writeoff posted OK`, SnackbarTypeSuccess)
        handleClose()
      })
      .catch(
        catchAPIError({
          defaultMessage: 'Failed posting Farley writeoff',
        })
      )
      .finally(() => setIsWorking(false))
  }

  return (
    <>
      <Button
        variant="outlined"
        size="small"
        color="secondary"
        onClick={() => {
          setIsOpen(true)
        }}>
        Writeoff Farley Uncollectible
        {isWorking && (
          <>
            &nbsp;
            <DesignSuite2023.LoadingSpinner size={20} show={isWorking} />
          </>
        )}
      </Button>

      <Dialog
        open={isOpen}
        onClose={() => {
          // no-op: keep dialog open unless finished or user clicks cancel
        }}
        maxWidth={'lg'}>
        <DialogTitle>
          Writeoff Farley Uncollectible: {formatAmount(data.Amount)}
        </DialogTitle>
        <DialogContent style={{ width: '1080px' }}>
          <StyledContent>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12}>
                <Typography>
                  This negative payable amount represents money that Farley owes
                  Zero. This action will write it off as uncollectible.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <RenderNotesField
                  name="notes"
                  label="Notes"
                  value={postWriteoffFarleyUncollectibleParams.notes}
                  setter={setter}
                />
              </Grid>
            </Grid>
          </StyledContent>
        </DialogContent>

        <DialogActions>
          <DesignSuite2023.GridLR
            left={
              <>
                <Button
                  color="secondary"
                  variant="outlined"
                  size="small"
                  onClick={handleClose}>
                  Cancel
                </Button>
              </>
            }
            right={
              <>
                <Button
                  color="primary"
                  variant="outlined"
                  onClick={doPostWriteoffFarleyUncollectible}>
                  Writeoff
                </Button>
              </>
            }
          />
        </DialogActions>
      </Dialog>
    </>
  )
}

function formatAmount(amount: string): string {
  if (!amount || amount === '0') return '$0.00'

  return `$${amount.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
}
